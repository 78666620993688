@font-face {
  font-family: 'SamsungOne 400';
  src: url('./fonts/SamsungOne-400.woff2') format('woff2'),
    url('./fonts/SamsungOne-400.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'SamsungOne 400', 'Roboto';
}

.top-section {
  background: linear-gradient(
    138deg,
    rgb(111 166 218) 65%,
    rgb(45 117 171) 100%
  );
  position: fixed;
  width: 100vw;
  z-index: 9;
}

.site-wrapper {
  background: linear-gradient(
    60deg,
    rgba(182, 187, 217, 1) 40%,
    rgba(109, 160, 208, 1) 65%,
    rgba(35, 106, 160, 1) 100%
  );
}

.main-content {
  padding-top: 13rem;
}

.bottom-nav {
  background: linear-gradient(
    60deg,
    rgba(182, 187, 217, 1) 40%,
    rgba(109, 160, 208, 1) 65%,
    rgba(35, 106, 160, 1) 100%
  );
}

.video-player {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  max-height: 100%;
}

.text-xs {
  font-size: 0.85rem;
  letter-spacing: -0.7px;
}

h3 {
  letter-spacing: -1px;
}

.text-opace {
  color: rgba(255, 255, 255, 0.5);
}

.youtube-video {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.youtube-container {
  position: relative;
}

.svg-100 svg {
  width: 100%;
  height: 100%;
}

/* Analytics.css */

.analytics-container {
  max-width: 600px;
  width: 80vw;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.analytics-header {
  font-size: 24px;
  margin-bottom: 20px;
}

.date-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.date-input {
  display: flex;
  flex-direction: column;
}

.fetch-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
}

.fetch-button:hover {
  background-color: #0056b3;
}

.analytics-data {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.analytics-item {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 4px;
  flex: 1;
  margin-right: 10px;
}

.analytics-item h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.analytics-item p {
  margin: 0;
  font-size: 14px;
}
